import React from "react";
import Link from "../Global/Link";

const Footer = () => {
  return (
    <footer className="w-100 py-5 small brand-background-blacker brand-text-white">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-12 text-center">
            <ul className="list-unstyled d-flex flex-column flex-md-row justify-content-center text-center brand-link-white mb-0">
              <li className="m-4">
                <Link to="/">Home</Link>
              </li>
              <li className="m-4">
                <Link to="/rsvp/">RSVP</Link>
              </li>
              <li className="m-4">
                <Link to="/schedule/">Schedule</Link>
              </li>
              <li className="m-4">
                <Link to="/lineup/">Big Day Line-Up</Link>
              </li>
              <li className="m-4">
                <Link to="/gifts/">Gifts</Link>
              </li>
              <li className="m-4">
                <Link to="/hotels/">Hotels</Link>
              </li>
              <li className="m-4">
                <Link to="/contact/">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
