export const isBrowser = () => typeof window !== "undefined";

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("guest");

export const urlParams = () =>
  isBrowser() && new URLSearchParams(window.location.search).get("a");

const setUser = () => window.localStorage.setItem("guest", urlParams());

export const handleLogin = ({ username, password }) => {
  if (username === `john` && password === `pass`) {
    return setUser({
      username: `john`,
      name: `Johnny`,
      email: `johnny@example.org`,
    });
  }

  return false;
};

export const isLoggedIn = () => {
  const user = getUser();
  const params = urlParams();

  if (!user) {
    // There is no user in localstorage
    if (!params) {
      // There are no params in the URL
      // alert("Return: false");
      return false;
    } else {
      // There are params
      setUser();
    }
  } else {
    // There is a user in local storage
    if (params) {
      if (params !== user) {
        setUser();
        // alert("true");
      }
    }
  }

  return getUser();
};
