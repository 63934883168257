import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

// ====

const Meta = (props) => {
  const { debug, openGraph } = props;

  const page = openGraph;

  const domain = "https://www.cp4rp.com";

  const image =
    "https://a.storyblok.com/f/158885/1920x1080/c55c5d003d/graph.png";

  let url = domain;

  if (typeof window !== "undefined") {
    url = window.location.href;
  }

  if (debug === true) {
    return (
      <div className="w-100 p-3 border bg-light border-warning">
        <h3>Debug Translator</h3>
        <p>
          <strong>Domain:</strong> {domain}
        </p>
        <p>
          <strong>Page URL:</strong> {url && url}
        </p>
        <p>
          <strong>Page title:</strong> {page.title && page.title}
        </p>
        <p>
          <strong>Social / SEO title:</strong>{" "}
          {page.socialTitle && page.socialTitle}
        </p>
        <p>
          <strong>Description:</strong> {page.description && page.description}
        </p>
      </div>
    );
  }

  return (
    <Helmet>
      <title>{page.title}</title>
      <meta name="description" content={page.description} />
      <meta property="og:url" content={`${url}`} />
      {page.type && <meta property="og:type" content={page.type} />}
      <meta property="og:title" content={page.socialTitle} />
      <meta property="og:description" content={page.description} />
      <meta property="og:site_name" content="Claire & Richy" />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={page.socialTitle} />
      <meta property="og:image:width" content="960" />
      <meta property="og:image:height" content="540" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="robots" content="noindex" />
    </Helmet>
  );
};

export default Meta;

Meta.propTypes = {
  debug: PropTypes.bool,
  meta: PropTypes.object,
  openGraph: PropTypes.object,
};

Meta.defaultProps = {
  debug: false,
  meta: null,
  openGraph: {
    title: null,
    description: "",
    richtext: true,
    author: "",
    image: "",
    type: "website",
  },
};
