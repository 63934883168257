import React from "react";
import { library, config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";
import Footer from "./Footer";
import Meta from "../../utils/Meta";
import { isLoggedIn } from "../../services/auth";

config.autoAddCss = false;
library.add(fab, fas);

const openGraph = {
  type: "website",
  title: "CP 4 RP",
  socialTitle: "All the details for Claire & Richy's big day",
  description: "20th May 2023 | Left Bank, Leeds",
};

export default function Layout({ children }) {
  return (
    <>
      {isLoggedIn() ? (
        <>
          <Header />
          <main className="brand-main-content overflow-hidden">{children}</main>
          <Footer />
        </>
      ) : (
        <>
          <Meta openGraph={openGraph} />
          <section className="py-5 px-3 text-center">
            <p>Sorry, you need a special link to access this page.</p>
            <p>
              Please refer back to your original invitation (text or email) to
              get the link.
            </p>
            <p>If you have any problems, please let us know.</p>
          </section>
        </>
      )}
    </>
  );
}
