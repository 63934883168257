import React, { useState } from "react";
import Link from "../Global/Link";

// ====

const Header = () => {
  const [burgerStatus, setBurgerStatus] = useState(false);

  // Site has the pages in it!
  return (
    <>
      <header>
        <nav className="">
          <div
            className={`brand-menu ${burgerStatus ? "brand-menu-open" : ""}`}
          >
            <ul className="list-unstyled text-center fs-1">
              <li className="my-4">
                <Link
                  className="brand-font brand-menu-item"
                  to="/"
                  onClick={() => setBurgerStatus(burgerStatus ? false : true)}
                >
                  Home
                </Link>
              </li>
              <li className="my-4">
                <Link
                  className="brand-font brand-menu-item"
                  to="/rsvp/"
                  onClick={() => setBurgerStatus(burgerStatus ? false : true)}
                >
                  RSVP
                </Link>
              </li>
              <li className="my-4">
                <Link
                  className="brand-font brand-menu-item"
                  to="/schedule/"
                  onClick={() => setBurgerStatus(burgerStatus ? false : true)}
                >
                  Schedule
                </Link>
              </li>
              <li className="my-4">
                <Link
                  className="brand-font brand-menu-item"
                  to="/gifts/"
                  onClick={() => setBurgerStatus(burgerStatus ? false : true)}
                >
                  Gifts
                </Link>
              </li>
              <li className="my-4">
                <Link
                  className="brand-font brand-menu-item"
                  to="/hotels/"
                  onClick={() => setBurgerStatus(burgerStatus ? false : true)}
                >
                  Hotels
                </Link>
              </li>

              <li className="my-4">
                <Link
                  className="brand-font brand-menu-item"
                  to="/contact/"
                  onClick={() => setBurgerStatus(burgerStatus ? false : true)}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <span className="position-absolute top-0 end-0 p-3 pt-2 p-md-4 brand-menu-button-placement">
            <span
              className="brand-menu-button-container"
              role="button"
              onClick={() => setBurgerStatus(burgerStatus ? false : true)}
            >
              <div
                className={`brand-menu-button ${
                  burgerStatus ? "brand-menu-button-open" : ""
                }`}
              />
            </span>
          </span>
        </nav>
      </header>
    </>
  );
};

export default Header;
